
import React from 'react'
import './Navbar.css'
import logo_nm from './images/logo_nm.png'

const Navbar = () => {

  function redirect(){
    alert();
    //window.href="";
    }

  return (
<>
<nav class="navbar sticky navbar-custom navbar-default ">
  <div class="container-fluid">
    <div class="navbar-header ">
      <button type="button" class="navbar-toggle btn btn-light" data-toggle="collapse" data-target="#myNavbar" >
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>                        
      </button>
      <a class="navbar-brand" href="/">
        <img src={logo_nm} alt="logo"/>
        </a>
    </div>
    <div class="collapse navbar-collapse" id="myNavbar" >
      <ul class="nav justify-content-center navbar-nav">
        {/*<li className="name colour">Navier Machines</li>*/}
        <li><a href="/" style={{color:'#000'}}>Home</a></li>
        <li><a href="#about" style={{color:'#000'}}>About</a></li>
       
        <li class="dropdown">
          <a class="dropdown-toggle " data-toggle="dropdown" href="#" style={{color:'#000'}} >Solutions<span class="caret"> </span></a>
          <ul class="dropdown-menu txt">
{/*           <li>
                <a class="dropdown-item" href="#agri">
                  Agriculture &raquo;
                </a>
                <ul class="dropdown-menu dropdown-submenu">
                <li>
                    <a class="dropdown-item" href="#">Health monitoring</a>
                 </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      <p> we monitor crop health,harvest etc.<br/>we also provide monitoring growth of the crops.</p>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">Submenu item 2</a>
                 </li>
                    
                </ul>   
              </li> */}

            <li><a href="#agri">Agriculture</a></li>
            <li><a href="#ins">Inspection</a></li>
            <li><a href="#med">Medical</a></li>

           

          </ul>
        </li>     
               <li><a href="#contact" style={{color:'#000'}}>Contact</a></li>
              
               
         
              {/*<ul class="nav navbar-nav navbar-right">
              
                <li><a href="#"><span class="glyphicon glyphicon-log-in"></span> Register</a></li>
                <span> </span>
              </ul>*/}
        </ul>
        
      
    </div>
  </div>
</nav>
</>
  );  }

  

export default Navbar;

