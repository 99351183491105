import styled from "styled-components";
export const ServicesContainer=styled.div`
    
  
    background-color: white;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
   
    z-index:1;
   @media screen and (max-width:768px) {
        height: 1500px;
    }
    @media screen and (max-width:1024px) {
    height:max-content;
    }
    @media screen and (max-width:480px) {
        height: 1500px;
        
    }
    @media screen and (max-width:390px) {
        height:1500px;
        width: 390px;
     
      
    }
    @media screen and (max-width:820px){
       
      font-size: 32px;
    }   

`;
export const ServicesWrapper=styled.div`
   max-width: 1200px;
   margin: 0 auto;
   display: grid;
   grid-template-columns:1.5fr 1.5fr 1.5fr;
   align-items: center;
   grid-gap: 16px;
   padding: 0 50px;
  
   margin-bottom: 250px;
   margin-top: 60px;

   @media screen and (max-width:1180px) {
        grid-template-columns: 2fr 2fr;
   }
   @media screen and (max-width:768px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
   }
   @media screen and (max-width:1024px){
       
       margin-top: 200px;
    }
    
    @media screen and (max-width:820px){
       
       margin-top: 350px;
    }
    @media screen and (max-width:390px){
       
       margin-top: 150px;
      
    }
    @media screen and (max-width:412px){
       
       margin-top: 150px;
      
    }
    @media screen and (max-width:414px){
       
       margin-top: 150px;
      
    }
    @media screen and (max-width:375px){
       
       margin-top: 100px;
      
    }
    @media screen and (max-width:390px) {
        
       margin-top: 250px;
      }
      @media screen and (max-width:430px){
       margin-top:250px;
     }

   
`;

export const ServicesCard =styled.div`
       background-color:white;
        display: block;
        flex-direction:column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 10px;
        max-height: 1200px;
        padding: 10px;
        box-shadow: 0 1px 3px rgba(0,0,0,0,2);
        transition: all 0.2s ease-in-out;
        
      

        &:hover{
            transform:scale(1.02);
            transition: all 0.2s all ease-in-out;
            cursor: pointer;
            background-color: whitesmoke;
            
        }
       

`;

export const ServicesH2 =styled.h2`
    font-size: 2rem;
    margin-bottom:10px;
    font-weight: bold;
    @media screen and (max-width:1024px){
       
       margin-top: 10px;
    }

   
    
`;

export const ServicesP=styled.p`
    font-size: 15px;
    text-align: justify;
    margin-bottom: 30px;
    border-radius: 2px;

`;
export const ServicesP1=styled.p`
    font-size: 15px;
    display: inline-block;
    text-align: justify;
    
   
    @media screen and (max-width:1180px){
       margin-top :15px ;
      margin-bottom: -50rem;
    }
    @media screen and (max-width:1024px){
       
       margin-top: 30px;
    }
    @media screen and (max-width:820px){
       
       margin-top: 50px;
       margin-bottom: -90px;
       font-size: 20px;
    }
    @media screen and (max-width:430px){
       
       margin-top: 50px;
       margin-bottom: -180px;
      
    }
   


`;
export const ServicesP2=styled.p`
    font-size: 15px;
    text-align: justify;
    margin-bottom: 30px;
    text-decoration-color: black;
`;



export const ServicesH1=styled.h1`

    font-size: 3rem;
    color: #000;
    margin-bottom: 5px;
    margin-top: 100px;
   
    

    @media screen and (max-width:820px) {
        font-size:24px;
       margin-top: 50px;
       
    }
    @media screen and (max-width:1180px){
       margin-bottom :20px ;
       font-size:32px;
       margin-top: -300px;
    }
    @media screen and (max-width:1024px){
       
       margin-top: 10px;
    }
    @media screen and (max-width:430px){
       
       margin-top: 100px;
      
    }
    @media screen and (max-width:768px){
       font-size: 32px;
    }
    @media screen and (max-width:820px){
       
       margin-top: -100px;
    }
    @media screen and (max-width:375px){
      margin-top:-500px;
      font-size: 5px;
            
    }


`;
export const ServicesIcon=styled.img`
    height: 200px;
    width: 320px;
    margin-bottom: 10px;
    align-items: center;
    border-radius: 2px;

`;
export const ServicesWrapper1=styled.div`

max-width:700px;
padding: 5px 20px;
display:flex;
flex-direction: column;
align-items:left;
margin-bottom: -50px;


   @media screen and (max-width:1180px) {
        grid-template-columns: 2fr 2fr;
   }
   @media screen and (max-width:768px) {

    grid-template-columns: 1fr ;
    margin-top: 250px;
      padding-top: 2px;
   }
   @media screen and (max-width:820px){
       
       margin-top: 200px;
    }
    @media screen and (max-width:390px){
       
       margin-top: 390px;
      
    }
    @media screen and (max-width:430px){
       
       margin-top: 250px;
      
    }
   
`;