import React from 'react'
import medical1 from '../images/medical1.png'
import medical2 from '../images/medical2.png'
import medical3 from '../images/medical3.png'
import {ServicesWrapper1,ServicesP1,ServicesContainer,ServicesH1,ServicesWrapper,
    ServicesIcon,ServicesCard,ServicesH2,ServicesP,ServicesP2  } from './MedicalElements';


const MedicalStyling = () => {
  return (
     <>
    
      <ServicesContainer id="med">
     
        <ServicesWrapper1>
        <ServicesH1>MEDICAL</ServicesH1>
          <ServicesP1>
          Our state-of-the-art machine learning and deep learning solutions have been meticulously crafted to assist doctors in diagnosing diseases and identifying patient risks with precision and efficiency.
          Our core focus lies in delivering a comprehensive AI product-based approach to extract invaluable insights from patient healthcare data. With our innovative solutions, you can:
         </ServicesP1>
        </ServicesWrapper1>
       
        <ServicesWrapper>

           <ServicesCard>
            <ServicesIcon src={medical1}/>
            <ServicesH2>Diagnostic Accuracy </ServicesH2>
            <ServicesP>
            Our advanced technology augments the diagnostic process, offering medical practitioners enhanced accuracy in identifying diseases and assessing patient risks.
            </ServicesP>
          </ServicesCard>

           <ServicesCard>
            <ServicesIcon src={medical2}/>
            <ServicesH2>Patient Care </ServicesH2>
            <ServicesP2> 
            By leveraging our AI products, you'll be better equipped to provide tailored care and treatment plans, improving patient outcomes.

            </ServicesP2>
          </ServicesCard>
      
           
          <ServicesCard>
            <ServicesIcon src={medical3}/>
            <ServicesH2>Healthcare Operations</ServicesH2>
            <ServicesP2> 
            We help streamline healthcare operations, making data-driven decisions that enhance the overall efficiency and effectiveness of your practice.

            </ServicesP2>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
   </>
  )
}


export default MedicalStyling;