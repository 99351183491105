import styled from 'styled-components';

export const InfoContainer=styled.div`
    background:#fff;

    @media screen and (max-width:768px){
        padding:100px 0;
    }
`;
export const InfoWrapper=styled.div`
    display:grid;
    z-index:1;
    height:800px;
    width:100%;
    max-width:1100px;
    margin-right:auto;
    margin-left:auto;
    padding:0 24px;
    justify-content:left;
`;
export const InfoRow=styled.div`
    margin-top: 150px;
    display:grid;
    grid-auto-columns:minmax(auto,fit);
    align-items:left;
    grid-template-areas:${({imgStart})=> imgStart ? `'col2 col1'`:`'col1 col2'`};

    @media screen and (max-width:768px){
        grid-template-areas:${({imgStart})=> imgStart ? `'col1 col2'`:`'col1 col1' 'col2 col2'`};
 
    }

`;
export const Column1=styled.div`
    margin-top: 20px;
    margin-bottom:15px;
    padding:0px 15px;
    grid-area:col1;

`;
export const Column2=styled.div`
 margin-top: 20px;
    margin-bottom:15px;
    padding:0 15px;
    grid-area:col2;

`;
export const TextWrapper=styled.div`
   
    max-width:540px;
    padding-top:0;
    padding-bottom:60px;
    margin-right:10px;
`;
export const Topline=styled.p`
    color: #000;
    font-size:30px;
    line-height:16px;
    font-weight:500;
    text-align: left;
    margin-bottom:40px;
    margin-right:40px;
    margin-top: 25px;
`;
export const Heading=styled.h1`
    margin-bottom:24px;
    font-size:15px;
    line-height:1.1;
    font-weight:400;
    margin-right:50px;
    color:white;
    text-align: left;

    @media screen and (max-width:468px){
        font-size:30px;

    }
`;
export const Subtitle=styled.p`
    max-width:450px;
    margin-bottom:35px;
    font-size:15px;
    line-height:25px;
    color: #000;
     
    
    text-align:justify;
    
`;

export const ImgWrap=styled.div`
    max-width:100%;
    height:100%;
`;
export const Img=styled.img`
    width:500px;
    margin:0 0 50px 0;
    padding-right:0;
    height:350px;
`;

