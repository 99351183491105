import React from 'react';
import {InfoContainer,
        InfoWrapper,
        InfoRow,
        Column1,
        Column2,
        TextWrapper,
        Topline,
        Heading,
        Subtitle,
        ImgWrap,
        Img} from './ContactElements';
        import contact from '../images/contact.jpg'

      
const ContactStyling= () =>
{
        return(  
        <>
         <InfoContainer id="contact" >
             <InfoWrapper>
                <InfoRow >
                 <Column1>
                    <TextWrapper>
                        <Topline>Contact Us:</Topline>
                        <Heading >
                            Navier Machines Pvt Ltd.<br/> Hyderabad,Telangana.
                           
                        </Heading>
                        
                        <Subtitle >
                            Email: contact@naviermachines.ai

                        </Subtitle>
                    </TextWrapper>
                </Column1>
                <Column2>
                    <ImgWrap>
                       <Img src={contact} alt="contact" />
                    </ImgWrap>
                </Column2>
            </InfoRow>
        </InfoWrapper>
    </InfoContainer>  
    </>
 );
};

export default ContactStyling;