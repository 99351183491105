import styled from 'styled-components';


export const InfoContainer=styled.div`
   
    @media screen and (max-width:768px){
        padding:100px 0;
    }
`;
export const InfoWrapper=styled.div`
    display:grid;
    z-index:1;
  

    width:100%;
    max-width:1100px;
    margin-right:auto;
    margin-left:auto;
    padding:0 24px;
    justify-content:left;
`;
export const InfoRow=styled.div`
    display:grid;
    grid-auto-columns:minmax(auto,fit);
    align-items:center;
    margin-top: 300px;
    
    grid-template-areas:${({imgStart})=> imgStart ? `'col2 col1'`:`'col1 col2'`};

    @media screen and (min-width: 280px) and (max-width:1080px){
    grid-template-areas:${({imgStart})=> imgStart ? `'col1 col2'`:`'col1 col1' 'col2 col2'`};
    margin-top:350px;
   
    }
    @media screen and (max-width:390px) {
        margin-top:50px
    }
    @media screen and (max-width:412px) {
        margin-top:50px
    }
`;
export const Column1=styled.div`
    margin-bottom:15px;
    padding:0px 15px;
    grid-area:col1;
    @media screen and (max-width:390px) {
        margin-top:50px;
       
    }
    @media screen and (max-width:430px) {
        margin-top:50px;
       
    }
   

`;
export const Column2=styled.div`
    margin-bottom:15px;
    padding:0 15px;
    grid-area:col2;
    @media screen and (max-width:390px) {
        margin-top:50px
    }
    @media screen and (max-width:430px) {
        margin-top:50px;
       
    }

`;
export const TextWrapper=styled.div`
    max-width:540px;
    padding-top:0;
    padding-bottom:60px;
    margin-right:10px;
`;
export const Topline=styled.p`
    color:#000;
    font-size:20px;
    line-height:16px;
    font-weight:500;
    text-align: left;
    
    margin-bottom:16px;
    margin-right:40px;
    @media screen and (max-width:412px) {
        margin-top:150px;
    }
    @media screen and (max-width:430px) {
        margin-top:-270px;
       
    }
    @media screen and (max-width:390px) {
        
        font-size: 20px;
        margin-top: 30px;
        
     }
    
`;
export const Heading=styled.h1`
    margin-bottom:24px;
    font-size:28px;
    line-height:1.1;
    font-weight:400;
    margin-right:50px;
    color:black;
    text-align: center;
    @media screen and (max-width:440px){
        
        margin-top: 500px;
        font-size:30px;

    }
    @media screen and (max-width:390px) {
        margin-top:70px
    }
    @media screen and (max-width:412px) {
        margin-top:150px;
    }
    @media screen and (max-width:430px) {
        
       font-size: 20px;
       margin-top: -5px;
       
    }
`;
export const Subtitle=styled.p`
    max-width:450px;
    margin-bottom:35px;
    font-size:15px;
    line-height:25px;
      
    text-align:left;
    color:black;
    @media screen and (max-width:430px) {
        
        font-size: 20px;
        margin-top: -5px;
        
     }
     `

export const ImgWrap=styled.div`
    max-width:100%;
    height:100%;
    @media screen and (max-width:430px) {
        
        
        margin-top: -50px;
        
     }
`;
export const Img=styled.img`
    width:300px;
    margin:0 0 50px 0;
    padding-right:0;
    height: 200px;
    margin-right: 50px;
`;

