import React from 'react';
import {InfoContainer,
        InfoWrapper,
        InfoRow,
        Column1,
        Column2,
        TextWrapper,
        Topline,
      
        Subtitle,
        ImgWrap,
        Img} from './AboutElements';
        import about from '../images/about.jpeg'

        
      
const InfoSection = () =>
{
        return(  
        <>
         <InfoContainer id="about" >
             <InfoWrapper>
                <InfoRow>
                 <Column1>
                    <TextWrapper>
                        <Topline>About Us!!!</Topline>
                        
                        <Subtitle>
                        At Navier Machines,
                         we are a cohesive team of dedicated researchers and innovators,
                          driven by an unwavering passion for tackling challenging problems using AI.
                          </Subtitle>
                          <Subtitle>
                        Our forte is crafting cutting-edge AI technologies that prioritize reliability and cater to various applications.
                         Our team's mission is to fuel innovation that steers humanity towards a more visually intelligent future, 
                        where AI solutions are seamlessly integrated into everyday life, making it more innovative and efficient
                        </Subtitle>
                    </TextWrapper>
                </Column1>
                <Column2>
                    <ImgWrap>
                       <Img src={about} alt="about us" />
                    </ImgWrap>
                </Column2>
            </InfoRow>
        </InfoWrapper>
    </InfoContainer>  
    </>
 );
};
export default InfoSection;