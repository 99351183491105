import React from 'react'
import agritech1 from '../images/agritech1.png'
import agritech2 from '../images/agritech2.png'
import agritech3 from '../images/agritech3.png'

import {ServicesWrapper1,ServicesP1,ServicesContainer,ServicesH1,ServicesWrapper,
  ServicesIcon,ServicesCard,ServicesH2,ServicesP  } from './AgriElements';
import { ServicesP2 } from '../Medical/MedicalElements';

const AgriStyling = () => {
  return (
   <>
      <ServicesContainer id="agri">
        
     
        <ServicesWrapper1>
        <ServicesH1>AGRITECH</ServicesH1>
          <ServicesP1>
         With our intelligent AI solutions, you can expect the following:
         </ServicesP1>
        </ServicesWrapper1>
       
        <ServicesWrapper>

           <ServicesCard>
             <ServicesIcon src={agritech1}/>
            <ServicesH2>Health Monitoring </ServicesH2>
            <ServicesP> Our technology enables you to keep a close eye on the well-being of your crops. Through data analysis and real-time monitoring, we help you ensure the health and vitality of your plants.
            </ServicesP>
          </ServicesCard>

           <ServicesCard>
            <ServicesIcon src={agritech2}/>
            <ServicesH2>Change Detection </ServicesH2>
            <ServicesP2> Stay ahead of changes in your agricultural landscape. Our AI tools are designed to identify variations and deviations, allowing you to take timely action.


            </ServicesP2>
          </ServicesCard>
      
           
          <ServicesCard>
            <ServicesIcon src={agritech3}/>
            <ServicesH2>Yield Prediction</ServicesH2>
            <ServicesP2> 
            Maximizing your crop yields is essential. Our predictive models leverage data to estimate and optimize your future yields, ensuring you make informed decisions.
            </ServicesP2>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
   </>
  )
}

export default AgriStyling;