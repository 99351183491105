import React from 'react'
import inspection1 from '../images/inspection1.png'
import inspection2 from '../images/inspection2.png'
import inspection3 from '../images/inspection3.png'

import {ServicesWrapper1,ServicesP1,ServicesContainer,ServicesH1,
    ServicesWrapper,ServicesIcon,ServicesCard,ServicesH2,ServicesP,ServicesP2  } from './InsElements';

const InspectionStyling = () => {
  return (
   <>
      <ServicesContainer id="ins">
     
        <ServicesWrapper1>
        <ServicesH1>INSPECTION</ServicesH1>
          <ServicesP1>
         The ultimate goal is increasing operational life-cycle and boosting productivity.
          We specialize in providing comprehensive AI product-based solutions for infrastructure monitoring.
         </ServicesP1>
        </ServicesWrapper1>   
        <ServicesWrapper>
           <ServicesCard>
            <ServicesIcon src={inspection1}/>
            <ServicesH2>Buildings </ServicesH2>
            <ServicesP> 
              We offer advanced AI solutions to monitor and assess the health and safety of building structures, ensuring their longevity and performance.
            </ServicesP>
          </ServicesCard>

          <ServicesCard>
            <ServicesIcon src={inspection2}/>
            <ServicesH2>Bridges</ServicesH2>
            <ServicesP2> 
            Our technology is designed to inspect and monitor bridges, guaranteeing their structural integrity and safety.
            </ServicesP2>
          </ServicesCard>
           
          <ServicesCard>
            <ServicesIcon src={inspection3}/>
            <ServicesH2>Solar Farms</ServicesH2>
            <ServicesP> 
            We provide cutting-edge AI products for monitoring solar farms, helping you optimize energy production and maintain your investment. Unlocking the Power of AI
            </ServicesP>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
   </>
  )
}

export default InspectionStyling;