
import styled from "styled-components";

export const HeroContainer=styled.div`
    
    display:flex;
    justify-content:left;
    align-items:center;
    padding:0 30px;
    height:700px;
    position:relative;
    z-index:1;

`
export const HeroBg=styled.div`
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    width:100%;
    overflow:hidden;
`
export const VideoBg=styled.div`
    width:100%;
    height:900px;
    object-fit:cover;
    `
export const Img=styled.img`
width:100%;

height:900px;
`;

export const HeroContent=styled.div`
z-index:3;
max-width:700px;
position:absolute;
padding: 5px 20px;
display:flex;
flex-direction: column;
align-items:left;

text-align: left;
margin-bottom: 200px;

`
export const Heroh1=styled.h1`
margin-top:200px;
color:#fff;
font-size:40px;
text-align:left;
@media screen and (max-width:768px){
    font-size:32px;

}
@media screen and (max-width:480px){
    font-size:24px;
}

`
export const HeroP=styled.p`
    margin-top:40px;
    color:#fff;
    font-size:20px;
    text-align:left;
    margin-right: 220px;
   
    @media screen and (max-width:768px){
        font-size:15px;
    }
    @media screen and (max-width:480px){
        font-size:15px;
    }

` 
