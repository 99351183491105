import React from 'react'
import img1 from '../images/img1.png'
import img2 from '../images/img2.png'
import medmain from '../images/medmain.jpeg'

import {ServicesWrapper1,ServicesP1,ServicesContainer,ServicesH1,ServicesWrapper,
  ServicesIcon,ServicesCard,ServicesH2,ServicesP,ServicesP3  } from './SolElements';
import { ServicesP2 } from '../Medical/MedicalElements';

const Solstyling = () => {
  return (
   <>
      <ServicesContainer>  
        <ServicesWrapper1>
        <ServicesH1>SOLUTIONS</ServicesH1>
        </ServicesWrapper1>
       
        <ServicesWrapper>
           <ServicesCard>
            <a href="#agri">
            <ServicesIcon src={img1}/>
            </a>
            <ServicesH2>AgriTech </ServicesH2>
            <ServicesP> 
            Our suite of products offers a comprehensive data-driven approach to address key challenges in the agricultural sector.
            </ServicesP>
          </ServicesCard>

           <ServicesCard>
           <a href="#ins">
           <ServicesIcon src={img2}/>
           </a>
            <ServicesH2>Inspection</ServicesH2>
            <ServicesP2>
            Our approach utilizes the most potent end-to-end computer vision models to deliver effective mapping and inspection of structures
            </ServicesP2>
          </ServicesCard>
      
           
          <ServicesCard>
            <a href="#med">
            <ServicesIcon src={medmain}/>
            </a>
            <ServicesH2>Medical</ServicesH2>
            <ServicesP3> 
            we are dedicated to empowering medical professionals with advanced Voice and Vision-based solutions that revolutionize the diagnostic process.
            </ServicesP3>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
   </>
  )
}

export default Solstyling;