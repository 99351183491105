import React from 'react';
import './App.css'

import Navbar from './components/Navbar';
import HeroStyling from './components/Home';
import AboutStyling from './components/About';
import Services from './components/Services';
import ContactStyling from './components/Contact';
import Solstyling from './components/Solutions';


function App() {
  
   return (
    <> 
    <Navbar/>
    <HeroStyling/>
    <AboutStyling/>
    <Solstyling/>
    <Services/>
    <ContactStyling/>  
     
    </>
  );
};

export default App;
